.ag-theme-quartz .ag-details-row,
.ag-theme-quartz-dark .ag-details-row,
.ag-theme-quartz-auto-dark .ag-details-row {
  padding: 0;
}

.ag-horizontal-right-spacer {
  width: auto !important;
  min-width: 0 !important;
}

.ag-row:hover {
  background: #242428 !important;
}

.ag-row[aria-expanded='true'] {
  background: #242428 !important;
}

.ag-full-width-container .ag-row {
  background: #242428 !important;
}

.ag-menu-list {
  background: #242428;
}

.ag-full-width-container .ag-row {
  max-height: none !important;
  overflow-y: auto;
}

.ag-group-contracted .ag-icon,
.ag-group-expanded .ag-icon {
  position: absolute !important;
  left: 25% !important;
}

@keyframes fadeOut {
  0% {
    background: #30f0d626;
  }
  100% {
    background: #171719;
  }
}

.react-resizable-handle,
.react-resizable-handle-se {
  position: relative;
  margin-top: 40px;
  top: 100%;
}

.react-grid-item > .react-resizable-handle::after {
  border-right-color: aliceblue !important;
  border-bottom-color: aliceblue !important;
}

.rates-only-grid .ag-header-cell-text {
  display: flex;
  justify-content: start;
  margin-left: 80px;
}

.ag-full-width-container .ag-row {
  max-height: none !important;
}

.ag-header-container {
  background: #171719;
}
